import tailSelect from "tail.select.js";

document.addEventListener('DOMContentLoaded', function () {
  const tabs = document.querySelectorAll('.tab-link');
  const contents = document.querySelectorAll('.tab-content');

  tabs.forEach(tab => {
    tab.addEventListener('click', function (event) {
      event.preventDefault();

      // Remove active class from all contents
      tabs.forEach(function(t) {
        if(t === tab) {
          t.classList.add('text-gray-900', 'border-indigo-500');
          t.classList.remove(
            'hover:border-gray-300',
            'hover:text-gray-700',
            'text-gray-500',
            'border-transparent'
          );
        } else {
          t.classList.remove('text-gray-900', 'border-indigo-500');
          t.classList.add('text-gray-500', 'hover:border-gray-300', 'hover:text-gray-700', 'border-transparent');

        }
      })
      contents.forEach(content => content.classList.remove('active'));

      const targetId = this.getAttribute('href').substring(1);
      document.getElementById(targetId).classList.add('active');
    });
  });
});

document.addEventListener("turbo:load", () => {
  const searchFiltersForm = document.getElementById("carriers-filters-form");

  if (!searchFiltersForm) return;

  const customerSelect = document.querySelector("select#source_type");
  const customerSelectInstanceName = customerSelect.getAttribute("data-tail-select");
  const cachedSelectInstance = tailSelect.inst[customerSelectInstanceName]
  const searchInput = document.getElementById("search-input");

  if (cachedSelectInstance) return;

  const customerSelectInstance = tailSelect(customerSelect, {
    placeholder: "Search by source",
    multiple: true,
    multiContainer: true,
    multiShowCount: false
  });

  const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => { callback(...args); }, wait);
    };
  };

  const handleSearchInputChange = debounce(() => { searchFiltersForm.requestSubmit(); }, 600);
  const handleSelectCustomerChange = () => { searchFiltersForm.requestSubmit(); }

  searchInput.addEventListener("input", handleSearchInputChange);
  customerSelectInstance.on("change", handleSelectCustomerChange);

  document.addEventListener("turbo:before-fetch-request", (e) => {
    const url = e.detail.url;
    const query = url.searchParams.get('q');

    if (!query) url.searchParams.delete('q');
  });
})

